import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a>
    <h2>
Prestige Classes
    </h2><a id="arcane-trickster"></a>
    <h3>
ARCANE TRICKSTER
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">
      <a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG177_WEB.jpg">
        <img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit
Die</b>: d4.
    </p><a id="arcane-trickster-requirements"></a>
    <h6>
Requirements
    </h6>
    <p className="initial">
      <p>{`To qualify to become an arcane trickster, a character must fulfill
all of the following criteria.`}</p>
    </p>
    <p>
      <b>Alignment</b>: Any nonlawful.
    </p>
    <p>
      <b>Skills</b>: Decipher Script 7 ranks, Disable Device 7 ranks,
Escape Artist 7 ranks, Knowledge (arcana) 4 ranks.
    </p>
    <p>
      <b>Spells</b>: Ability to cast mage hand and at least one arcane
spell of 3rd level or higher.
    </p>
    <p>
      <b>Special</b>: Sneak attack +2d6.
    </p>
    <h6>
Class Skills
    </h6>
    <p className="initial">
      <p>{`The arcane trickster's class skills (and the key ability for each
skill) are `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#appraise">{`Appraise`}</a>{` (Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#balance">{`Balance`}</a>{` (Dex), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#bluff">{`Bluff`}</a>{`
(Cha), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#climb">{`Climb`}</a>{` (Str), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#concentration">{`Concentration`}</a>{` (Con), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">{`Craft`}</a></p>
      <p>{`(Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#decipher-script">{`Decipher Script`}</a>{` (Int),`}</p>
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disable-device">Disable Device</a> (Int),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disguise">Disguise</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#escape-artist">Escape Artist</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
      <p>{`(Str), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#knowledge">{`Knowledge`}</a>{` (Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">{`Listen`}</a>{` (Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#move-silently">{`Move Silently`}</a>{` (Dex), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#open-lock">{`Open
Lock`}</a>{` (Dex), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#profession">{`Profession`}</a>{` (Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sense-motive">{`Sense Motive`}</a>{` (Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#search">{`Search`}</a>{` (Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sleight-of-hand">{`Sleight of Hand`}</a>{` (Dex),`}</p>
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#speak-language">Speak Language</a> (Int),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
      <p>{`(Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#swim">{`Swim`}</a>{` (Str), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#tumble">{`Tumble`}</a>{` (Dex), and `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#use-rope">{`Use
Rope`}</a>{` (Dex).`}</p>
    </p>
    <p>
      <b>Skill Points at Each Level</b>: 4 + Int modifier.
    </p><a id="table-the-arcane-trickster"></a>
    <p>
      <b>Table: The Arcane Trickster</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>
Level
          </th>
          <th style={{
            "width": "12%"
          }}>
Base
            <p>{`Attack Bonus`}</p>
          </th>
          <th style={{
            "width": "7%"
          }}>
Fort
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "7%"
          }}>
            <p>{`Ref`}</p>
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "7%"
          }}>
Will
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "24%"
          }}>
Special
          </th>
          <th style={{
            "width": "26%"
          }}>
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td>
1st
          </td>
          <td>
+0
          </td>
          <td>
+0
          </td>
          <td>
+2
          </td>
          <td>
+2
          </td>
          <td>
            <p>{`Ranged legerdemain 1/day`}</p>
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
2nd
          </td>
          <td>
+1
          </td>
          <td>
+0
          </td>
          <td>
+3
          </td>
          <td>
            <p>{`+3`}</p>
          </td>
          <td>
Sneak attack +1d6
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
3rd
          </td>
          <td>
+1
          </td>
          <td>
+1
          </td>
          <td>
            <p>{`+3`}</p>
          </td>
          <td>
+3
          </td>
          <td>
Impromptu sneak attack 1/day
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
4th
          </td>
          <td>
+2
          </td>
          <td>
+1
          </td>
          <td>
+4
          </td>
          <td>
+4
          </td>
          <td>
Sneak attack +2d6
          </td>
          <td>
            <p>{`+1 level of existing class`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
5th
          </td>
          <td>
+2
          </td>
          <td>
+1
          </td>
          <td>
+4
          </td>
          <td>
+4
          </td>
          <td>
            <p>{`Ranged legerdemain 2/day`}</p>
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
6th
          </td>
          <td>
+3
          </td>
          <td>
+2
          </td>
          <td>
+5
          </td>
          <td>
            <p>{`+5`}</p>
          </td>
          <td>
Sneak attack +3d6
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
7th
          </td>
          <td>
+3
          </td>
          <td>
+2
          </td>
          <td>
            <p>{`+5`}</p>
          </td>
          <td>
+5
          </td>
          <td>
Impromptu sneak attack 2/day
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
8th
          </td>
          <td>
+4
          </td>
          <td>
+2
          </td>
          <td>
+6
          </td>
          <td>
+6
          </td>
          <td>
Sneak attack +4d6
          </td>
          <td>
            <p>{`+1 level of existing class`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
9th
          </td>
          <td>
+4
          </td>
          <td>
+3
          </td>
          <td>
+6
          </td>
          <td>
+6
          </td>
          <td>
            <p>{`Ranged legerdemain 3/day`}</p>
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td className="last-row">
10th
          </td>
          <td className="last-row">
+5
          </td>
          <td className="last-row">
+3
          </td>
          <td className="last-row">
+7
          </td>
          <td className="last-row">
            <p>{`+7`}</p>
          </td>
          <td className="last-row">
Sneak attack +5d6
          </td>
          <td className="last-row">
+1 level of existing class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="arcane-trickster-class-features"></a>
    <h6>
Class Features
    </h6>
    <p className="initial">
All of the following are Class Features of the arcane trickster
prestige class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency</b>: Arcane tricksters gain no
proficiency with any weapon or armor.
    </p>
    <p>
      <b>Spells per Day</b>: When a new arcane trickster level is
gained, the character gains new spells per day as if he had also
gained a level in a spellcasting class he belonged to before
adding the prestige class. He does not, however, gain any other
benefit a character of that class would have gained, except for an
increased effective level of spellcasting. If a character had more
than one spellcasting class before becoming an arcane trickster,
he must decide to which class he adds the new level for purposes
of determining spells per day.
    </p><a id="arcane-trickster-ranged-legerdemain"></a>
    <p>
      <b>Ranged Legerdemain</b>: An arcane trickster can perform one of
the following class skills at a range of 30 feet: Disable Device,
Open Lock, or Sleight of Hand. Working at a distance increases the
normal skill check DC by 5, and an arcane trickster cannot take 10
on this check. Any object to be manipulated must weigh 5 pounds or
less.
    </p>
    <p>
An arcane trickster can use ranged legerdemain once per day
initially, twice per day upon attaining 5th level, and three times
per day at 9th level or higher. He can make only one ranged
legerdemain skill check each day, and only if he has at least 1
rank in the skill being used.
    </p>
    <p>
      <b>Sneak Attack</b>: This is exactly like the rogue ability of the
same name. The extra damage dealt increases by +1d6 every other
level (2nd, 4th, 6th, 8th, and 10th). If an arcane trickster gets
a sneak attack bonus from another source the bonuses on damage
stack.
    </p><a id="arcane-trickster-impromptu-sneak-attack"></a>
    <p>
      <b>Impromptu Sneak Attack</b>: Beginning at 3rd level, once per
day an arcane trickster can declare one melee or ranged attack he
makes to be a sneak attack (the target can be no more than 30 feet
distant if the impromptu sneak attack is a ranged attack). The
target of an impromptu sneak attack loses any Dexterity bonus to
AC, but only against that attack. The power can be used against
any target, but creatures that are not subject to critical hits
take no extra damage (though they still lose any Dexterity bonus
to AC against the attack).
    </p>
    <p>
At 7th level, an arcane trickster can use this ability twice per
day.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      